<template>
  <div class="page_content">
    <div class="list_content">
      <div class="list">
        <div class="list_title">围栏</div>
        <span
          :class="[!orderDetail.carId?'isUnusual':timeCssCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.railException)]"
        >{{!orderDetail.carId?'未安装设备':timeTextCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.railException)}}</span>
      </div>
      <div class="list">
        <div class="list_title">载重</div>
        <span
          :class="[!orderDetail.carId?'isUnusual':timeCssCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.loadException)]"
        >{{!orderDetail.carId?'未安装设备':timeTextCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.loadException)}}</span>
      </div>
      <div class="list">
        <div class="list_title">照片</div>
        <span
          :class="[!orderDetail.carId?'isUnusual':timeCssCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.picException)]"
        >{{!orderDetail.carId?'未安装设备':timeTextCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.picException)}}</span>
      </div>
      <div class="list">
        <div class="list_title">系统初审</div>
        <span
          :class="[!orderDetail.carId?'isUnusual':timeCssCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.isWaring)]"
        >{{!orderDetail.carId?'未安装设备':timeTextCementCheck(orderDetail.platformEndDate,orderDetail.chuchangriqi,orderDetail.isWaring)}}</span>
      </div>
      <div class="list" v-if="checkProgress>1">
        <div class="list_title">市场部</div>
        <span
          :class="[orderDetail.marketCheckStatus == 1?'isNormal':orderDetail.marketCheckStatus == 0?'':'isUnusual']"
        >{{orderDetail.marketCheckStatus | checkStatusFilter }}</span>
      </div>
      <div class="list" v-if="checkProgress>2">
        <div class="list_title">销售处</div>
        <span
          :class="[orderDetail.salesCheckStatus == 1?'isNormal':orderDetail.salesCheckStatus == 0?'':'isUnusual']"
        >{{orderDetail.salesCheckStatus | checkStatusFilter }}</span>
      </div>
      <div class="list" v-if="checkProgress>3">
        <div class="list_title">财务处</div>
        <span
          :class="[orderDetail.financialCheckStatus == 1?'isNormal':orderDetail.financialCheckStatus == 0?'':'isUnusual']"
        >{{orderDetail.financialCheckStatus | checkStatusFilter }}</span>
      </div>
    </div>
    <div class="exam_list">
      <div class="exam_meta" v-if="checkProgress>1">
        <div class="exam_title">市场部意见</div>
      </div>
      <div
        class="exam_content"
        v-if="checkProgress>1"
      >{{orderDetail.marketCheckMsg?orderDetail.marketCheckMsg:'-'}}</div>
      <div class="exam_meta" v-if="orderDetail.checkFiles">
        <div class="exam_title">市场部附件</div>
      </div>
      <div class="exam_content" v-if="orderDetail.checkFiles">
        <img
					:src="orderDetail.checkFiles?imgUrl+'/'+orderDetail.checkFiles:noPic"
					:alt="orderDetail.checkFiles?'':'没有图片'"
					srcset=""
					@click="imgPreview(orderDetail.checkFiles)"
				>
      </div>
      <div class="exam_meta" v-if="checkProgress>2">
        <div class="exam_title">销售处意见</div>
      </div>
      <div
        class="exam_content"
        v-if="checkProgress>2"
      >{{orderDetail.salesCheckMsg?orderDetail.salesCheckMsg:'-'}}</div>
      <div class="exam_meta" v-if="checkProgress > 3">
        <div class="exam_title">财务处意见</div>
      </div>
      <div
        class="exam_content"
        v-if="checkProgress > 3 && !(isExhibition ==1)"
      >{{orderDetail.financialCheckMsg?orderDetail.financialCheckMsg:'-'}}</div>
      <div class="exam_meta" v-if="!(isExhibition ==1)">
        <div class="exam_title">填写备注</div>
      </div>
      <div class="exam_content" v-if="!(isExhibition ==1)">
        <el-input
          type="textarea"
          placeholder="填写审核备注,最多500字"
          maxlength="500"
          :rows="4"
          v-model="checkMsg"
          show-word-limit
          style="width:100%"
        ></el-input>
        <!-- <u-upload :action="action" :fileList="fileList" width="152" height="152" max-count="4"></u-upload> -->
      </div>
      <div class="upload-container" v-if="checkProgress == 1 && !(isExhibition==1)">
        <van-uploader v-model="fileList" multiple max-count="1" :after-read="afterRead" style="margin-top: 15px"/>
      </div>
    </div>
    <div class="order_bottom" v-if="!(isExhibition ==1)">
      <div class="bottom_btn" style="background: #D9011B" @click="sureExamine(2)">不通过</div>
      <div class="bottom_btn" style="background: #F59A23" @click="goBack">待审核</div>
      <div class="bottom_btn" style="background: #12BC75" @click="sureExamine(1)">通过</div>
    </div>
  </div>
</template>
<script>
import { uploadUrl, imgUrl } from "@/utils/config.js";
import { uploadPhoto } from "@/utils/tool";
import { Dialog, Notify, ImagePreview } from "vant";
export default {
  data() {
    return {
      orderDetail: {},
      action: uploadUrl + "/upload",
      fileList: [],
      formData: {},
      checkProgress: null,
      showFileUpload: true,
      file: "",
      checkMsg: "",
      isExhibition: null,
    };
  },
  methods: {
    imgPreview(img) {
      let imgList = [];
      imgList.push(imgUrl +'/'+ img)
      ImagePreview({
        images: imgList,
        startPosition: 0,
        closeable: true
      });
    },
    goBack() {
      uni.navigateBack({
        delta: 2
      });
    },
    /* 审核订单 */
    async sureExamine(checkStatus) {
      Dialog.confirm({
        title: "提示",
        message: "您确定要将审核结果提交吗?"
      })
        .then(async option => {
          try {
            await this.$http.examineOrder({
              checkStatus, checkLevel: this.checkProgress, checkMsg: this.checkMsg, orderId: this.orderDetail.id,
            });
            if (this.file) {
              await this.$http.uploadOrderFile({ orderId: this.orderDetail.id, file: this.file });
            }
            Notify({
              type: "success",
              message: "订单审核成功。"
            });
            setInterval(() => {
              uni.switchTab({
                url: "/pages/examine/index"
              });
            }, 1000);
          } catch (e) {
            console.log(e);
          }
        }).catch(() => {
          console.log("取消");
        });
    },
    //   上传附件
    async afterRead(e) {
      try {
        const { bizContent: file } = await uploadPhoto(
          e.file,
          this.orderDetail
        );
        this.file = file;
        this.showFileUpload = false;
        this.$message("success", "添加附件成功");
      } catch (err) {
        console.log(err);
        this.showFileUpload = true;
        this.$message("success", "上传附件失败,请重新上传");
      }
    },
    /* 下载附件 */
    downloadFile() {
      let url = `${this.imgUrl}/downloadFile/${this.file}`;
      uni.navigateTo({
        url: '/pages/audit/index?url='+url
      })
    },
    /* 附件删除 */
    async deleteloadFile() {
      Dialog.confirm({
        title: "提示",
        message: "您确定要将审核结果提交吗?"
      }).then((options)=>{
        this.file = "";
        this.showFileUpload = true;
      }).catch((err)=> {
        console.log("取消了")
      })
    }
  },
  created() {
    console.log("参数", this.$route.params);
    this.orderDetail = this.$route.params.orderDetail;
    this.checkProgress = this.$route.params.checkProgress;
    this.isExhibition = this.$route.params.isExhibition;
    this.formData = {
      chuchangriqi: this.orderDetail.chuchangriqi,
      officeId: this.orderDetail.officeId,
      fahuodanhao: this.orderDetail.fahuodanhao
    };
    console.log("orderDetail", this.orderDetail);
  }
};
</script>
<style lang="less" scoped>
textarea{
  width: 100%;
}
.page_content {
  min-height: 100vh;
  position: relative;
}

.list_content {
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;

  .list {
    font-size: 12px;

    .list_title {
      color: #999;
    }

    span {
      display: inline-block;
      margin-top: 5px;
    }
  }
}

.exam_list {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px 15px;
  background: #fff;

  .exam_meta {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .exam_title {
      font-size: 12px;
      color: #999;
    }

    .exam_enclosure {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #0f8fd3;
      font-size: 12px;
    }
  }

  .exam_content {
    font-size: 12px;
    color: #353535;
    margin-top: 5px;
    margin-bottom: 5px;
    img{
      width: 80px;
      height: 80px;
    }
  }

  .exam_act {
    display: inline-block;
    margin-top: 6px;
    font-size: 12px;
    color: #2a9bd8;
  }
}

.order_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;

  .bottom_btn {
    cursor: pointer;
    width: 100px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    font-size: 16px;
    color: #fff;
    border-radius: 20px;
  }
}
.upload-container {
  display: flex;
  position: relative;
  padding-right: 10px;
  margin-top: 15px;
  span {
    display: inline-block;
    background-color: #65b763;
    color: #fff;
    width: 60px;
    padding: 0 6px;
    font-size: 12px;
    border-radius: 2px;
  }
}
</style>
